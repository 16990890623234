import React from 'react';

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <p>If you would like to get in touch, please email me at debasishbehera.contact@gamil.com</p>
    </section>
  );
};

export default Contact;
